import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { openSnackbar } from "../../redux/actions/snackbar";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";
import Adjust from "@material-ui/icons/Adjust";
import { CircularProgress, Button, Tooltip } from "@material-ui/core";
// core components
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import classObj from "./Navbar.module.css";
import MARXEED_LOGO from "../../assets/img/new_marxeed_logo.png";
import { onLoginSuccess, onLoggout, onPostLogin, onCallCheckPoint } from "../../redux/actions/auth";
//import { GoogleLogin } from "react-google-login";
import AuthButton from "components/AuthButton/AuthButton";
import ReportLoading from "./ReportLoading";
import CreditDrawer from "components/CreditDrawer/CreditDrawer";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { AccountCircleOutlined } from "@material-ui/icons";
//import { primaryColor } from "assets/jss/material-dashboard-react";
import { getCredits } from "../../redux/actions/credits";
import { formatDate } from "../../utils/helper";
import Tour from "reactour";
import { useLocation } from 'react-router-dom';
import { blogCurationSteps, ebookSteps, hashSteps, homeSteps, linkedInSteps, outboundAISteps, qaSteps, resourcesSteps, toTargetSteps, trendingBlogSteps, youtubeSteps } from "./SiteGuide";
import CreditSymbol from "components/CreditSymbol/CreditSymbol";
import coins_icon from '../../assets/img/coins_icon.png';
import { handleDrawerOpen } from "redux/actions/drawer";

Amplify.configure(awsconfig);

const useStyles = makeStyles(styles);

function Header({
  onLoginSuccess,
  onPostLogin,
  loginSuccess,
  response,
  onLoggout,
  color,
  handleDrawerToggle,
  onCallCheckPoint,
  qParam,
  getCredits,
  userEmail,
  creditsLoading,
  creditsAvailable,
  creditsUsage,
  location,
  openSnackbar,
}) {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [authButtonLoading, setAuthButtonLoading] = useState(true);
  const [url, setUrl] = useState(false);
  const locate = useLocation();
  const pathname = locate.pathname;
  const [isTourOpen, setIsTourOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleTour = () => {
    setIsTourOpen(true);
    dispatch(handleDrawerOpen());
  }

  useEffect(() => {
    onCognitoLoginSuccess();
    setTimeout(() => {
      setAuthButtonLoading(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(pathname) {
        setUrl(true);
    }
    else {
      setUrl(false);
    }
  }, [pathname]);

  function isSteps() {
    switch (pathname) {
      case "/home":
        return homeSteps;
      
      case "/trendingBlogs":
        return trendingBlogSteps;
      
      case "/hashtags":
        return hashSteps;
      
      case "/qasites":
        return qaSteps;
      
      case "/Youtube":
        return youtubeSteps;
      
      case "/LinkedIn":
        return linkedInSteps;
      
      case "/Ebook":
        return ebookSteps;
      
      case "/outboundai":
        return outboundAISteps;
      
      case "/toTarget":
        return toTargetSteps;
      
      case "/blogCuration":
        return blogCurationSteps;
      
      case "/resources":
        return resourcesSteps;
    
      default:
        return homeSteps;
    }
  }

  const onCognitoLoginSuccess = async () => {
    setAuthButtonLoading(true);
    try {
      const { idToken, accessToken, refreshToken } = await Auth.currentSession();
      onLoginSuccess({
        tokenId: idToken.jwtToken,
        profileObj: {
          imageUrl: idToken.payload.picture,
          givenName: idToken.payload.given_name,
          familyName: idToken.payload.family_name,
          email: idToken.payload.email,
          refreshToken: refreshToken.token,
          accessToken: accessToken.jwtToken,
        },
        expires_at: idToken.payload.exp,
      });
      setAuthButtonLoading(false);
      // Delay to wait for LocalStorage action completion.
      setTimeout(() => {
        onPostLogin();
        onCallCheckPoint(qParam);
      }, 500);
    } catch (e) {
      setAuthButtonLoading(false);
      console.error("ERROR OCCURRED WHILE FETCHING USER PROFILE ", e);
    }
  };

  // const onSuccess = useCallback(
  //   function(response) {
  //     const { googleId, tokenId, profileObj, tokenObj } = response;
  //     if (googleId && tokenId && profileObj) {
  //       onLoginSuccess({
  //         googleId,
  //         tokenId,
  //         profileObj,
  //         expires_at: tokenObj.expires_at,
  //       });
  //       setTimeout(() => {
  //         onPostLogin();
  //         onCallCheckPoint(qParam);
  //       }, 500);
  //     }
  //   },
  //   [onLoginSuccess, onPostLogin, qParam, onCallCheckPoint]
  // );

  const onGoogleLoggout = useCallback(
    function (response) {
      onLoggout();
      window.location.reload();
    },
    [onLoggout]
  );

  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  const toggleDrawer = () => {
    if (loginSuccess) {
      setDrawer(!drawer);
    }
  };

  const onSignOut = async () => {
    try {
      await Auth.signOut();
      onLoggout();
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const onAuthLogin = () => {
    localStorage.setItem("redirect_url", window.location.pathname);
    setAuthButtonLoading(true);
    Auth.federatedSignIn();
  };

  useEffect(() => {
    if (!creditsLoading && creditsAvailable <= 1) {
      openSnackbar({
        open: true,
        severity: "warning",
        message: "Insufficient Credits to run new analysis. Buy Credits using",
        path: "https://buy.stripe.com/3csbJefsfalY4XC8wx",
      });
    }
  }, [creditsAvailable, creditsLoading])

  useEffect(() => {
    if (userEmail) {
      getCredits(userEmail.profileObj.email);
    }
  }, [creditsAvailable, getCredits, location.search, loginSuccess, userEmail]);

  const [usageData, setUsageData] = useState([]);

  useEffect(() => {
    setUsageData(creditsUsage.map((item) => [item.credit_for, item.credits, `${formatDate(item.date_time)}`]));
  }, [creditsUsage]);

  //links redirect to dev and prod
  const hostName = window.location.hostname
  const redirectLogo = () => {
    window.open(
      'https://marxeed.com',
      '_blank'
    )
  }

  const redirectServices = () => {
    window.open(
      'https://marxeed.com/services/',
      '_blank'
    )
  }
  const redirectSupport = () => {
    window.open(
      'https://marxeed.com/support/',
      '_blank'
    )
  }

  function AdjustIcon(props) {
    return (
      <Adjust {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </Adjust>
    );
  }

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Tour accentColor="#3f51b5" steps={isSteps()} disableInteraction={true} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
      <CreditDrawer open={drawer} toggleDrawer={toggleDrawer} credit={creditsAvailable} creditsUsage={usageData} />
      <Toolbar className={classes.container}>
        <div
          className={classes.logoContainer + " " + classObj.logoConResponsive}
          onClick={redirectLogo}
          style={{ cursor: "pointer" }}>
          <img src={MARXEED_LOGO} className={classes.logo + " " + classObj.logoResponsive} alt="Marxeed Logo" />
        </div>

        <div className={classes.flex + " " + classObj.authIcon}>
          <ReportLoading isQparam={qParam} />
          <div style={{ alignItems: "center", display: "flex" }}>
            <ul className={classObj.navList}>
              <li onClick={redirectServices}>Services</li>
              <li onClick={redirectSupport}>Support</li>
              <li style={{ cursor: 'default', display: 'flex', flexDirection: 'row' }}>
                <Tooltip title={!loginSuccess ? "Please Sign In to View Credits" : ""}>
                  {loginSuccess 
                    ? <div style={{ lineHeight: '1em', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      Credits: 
                      <Tooltip title={loginSuccess ? `Credits Available : ${creditsAvailable}`: ''}>
                        <span onClick={toggleDrawer} style={{ cursor: 'pointer', marginLeft: '2px' }}>
                          {creditsLoading ? <CircularProgress style={{ color: "#fff", width: '15px', height: '15px', padding: '0px 10px' }} /> : <CreditSymbol clickable={false} icon={coins_icon} creditsAvailable={creditsAvailable}/>}
                        </span>
                      </Tooltip>
                      <span onClick={() => window.open("https://marxeed.com#pricing", "_blank")} style={{ cursor: 'pointer', fontWeight: '900', padding: '3px 5px', backgroundColor: '#ff0000', fontSize: '16px', borderRadius: '4px', marginLeft: '1px' }}>
                          +
                      </span>
                    </div>
                    : <span onClick={toggleDrawer}>Credits(...)</span>
                    }
                </Tooltip>
              </li>
              <li>
                { 
                  url ? (
                    <button id="Tour" style={{ cursor: "pointer", backgroundColor: "rgb(97, 120, 139)", border: 'none', color: '#fff', fontSize: '15px', marginRight: '0px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif, Franklin Gothic Medium', fontWeight: '200' }} onClick={toggleTour} disabled={pathname === '/aigeneratedblogs'}>
                      Site Guide
                    </button>
                  ) : ''
                }
              </li>
            </ul>
            {loginSuccess && response ? (
              <AuthButton profileObj={{ ...response.profileObj }} onGoogleLoggout={onGoogleLoggout} onSignOut={onSignOut} />
            ) : (
              <>
                <IconButton disabled={authButtonLoading} onClick={onAuthLogin} size={"medium"}>
                  {authButtonLoading ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    <AccountCircleOutlined style={{ fontSize: 35, color: "#fff" }} />
                  )}
                </IconButton>
              </>
            )}
          </div>
        </div>
        <Hidden mdUp implementation="css">
          <IconButton color="inherit" aria-label="open drawer" onClick={() => handleDrawerToggle()}>
            <Menu className={classObj.drawerToggle} />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({ auth, credits }) => ({
  ...auth,
  userEmail: auth.response,
  creditsAvailable: credits.availableCredits,
  creditsLoading: credits.loading,
  creditsUsage: credits.creditsUsage,
});

const mapDispatchToProps = {
  onLoginSuccess,
  onLoggout,
  onPostLogin,
  onCallCheckPoint,
  getCredits,
  openSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
